<template>
	<div class="wrapper consignor">
		<div class="main-title">
			<div class="name">发货员</div>
			<el-button type="primary" class="main-btn" @click="addConsignor">添加发货员</el-button>
		</div>
		<el-table class="main-table" :data="tableData" border header-row-class-name="table-header" highlight-current-row
			ref="editTable">
			<el-table-column label="序号" type="index" align="center" width="80"></el-table-column>
			<el-table-column label="发货员姓名" prop="name" align="center"></el-table-column>
			<el-table-column label="联系电话" prop="phone" align="center"></el-table-column>
			<el-table-column label="添加时间" prop="addTime" align="center" sortable></el-table-column>
			<el-table-column label="是否启用" prop="isUse" align="center">
				<template slot-scope="{row}">
					<el-tag class="state-btn" type="success" v-if="row.isUse == 0">启用</el-tag>
					<el-tag class="state-btn" type="danger" v-if="row.isUse == -1">禁用</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="center" class-name="table-operate">
				<template slot-scope="{row, $index}">
					<el-button type="primary" size="mini" round @click="exitClick(row)">编辑</el-button>
					<el-button type="danger" size="mini" round @click="deleteClick(row, $index)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background layout="prev, pager, next,total,jumper" :total="total" :current-page.sync="query.page"
			:page-size="query.pagesize" @current-change="getList"></el-pagination>
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="发货员姓名" prop="name">
					<el-input v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="phone">
					<el-input v-model="ruleForm.phone"></el-input>
				</el-form-item>
				<el-form-item label="是否启用:" prop="isUse">
					<el-switch active-value="0" inactive-value="-1" v-model="ruleForm.isUse" active-text="启用"
						inactive-text="禁用">
					</el-switch>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
					<el-button @click="dialogVisible = false">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	let checkPhone = (rule, value, callback) => {
		let reg = /^1[345789]\d{9}$/
		if (!reg.test(value)) {
			callback(new Error('请输入11位手机号'))
		} else {
			callback()
		}
	}
	import {
		fetchList,
		saveOrUpdate,
		deleteConsignor
	} from "@/api/consignor"

	export default {
		data() {
			return {
				ruleForm: {
					name: '',
					phone: '',
					isUse: '0'
				},
				rules: {
					name: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						type: 'number',
						validator: checkPhone,
						message: '请填写正确手机号',
						trigger: 'blur'
					}],
				},
				dialogTitle: "添加发货员",
				dialogVisible: false,
				tableData: [],
				total: 10,
				query: {
					keyword: '',
					page: 1,
					pagesize: 10
				}
			}
		},
		mounted() {
			this.getList()

		},
		methods: {
			getList() {
				fetchList(this.query).then(res => {
					this.tableData = res.data.list
					this.total = res.data.total
				})
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						saveOrUpdate(this.ruleForm).then(res => {
							if (res.code === 0) {
								this.dialogVisible = false
								this.getList()
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			exitClick(row) {
				this.dialogTitle = "编辑发货员"
				this.dialogVisible = true
				this.ruleForm = row

			},
			addConsignor() {
				this.dialogTitle = "添加发货员"
				this.dialogVisible = true
				this.ruleForm = {
					name: '',
					phone: '',
					isUse: '0'
				}
			},
			deleteClick(row, index) {
				this.$confirm("确定要永久删除该行数据吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.tableData.splice(index, 1)
						deleteConsignor(row.id).then(res => {
							if (res.code === 0) {
								this.$message({
									type: "success",
									message: "删除成功!",
								});
								this.getList()
							}
							
						})


					})
					.catch(() => {});
			},
		}
	}
</script>

<style>
</style>
